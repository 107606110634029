import {take, call, put, delay, select,takeLatest} from 'redux-saga/effects'
import { actionType } from '../Constants'
import { eventsActions, temporaryActions } from '../Actions'
import { API } from './common'
import axios from 'axios'
import qs from 'qs'

export default function* deleteSaga(){
	while (true){
		const { id, setDelete } = yield take(actionType.DELETE_EVENT_SAGA)
		yield call(deleteEvent, id, setDelete)
	}
}

function* deleteEvent(id, setDelete){
	try {
		yield put(temporaryActions.setLoading(true))
		yield put(temporaryActions.setGenericErrors({}))
		const { token } = yield select(state => state.login.token)

		const data = new FormData()
		data.append('token', token)
		data.append('id', id)

		const { data: { events } } = yield axios.post(API + 'delete/', data)

		yield put(eventsActions.setEvents(events))
		setDelete(false)
	} catch (error) {
		// console.log('error delete',error.response);
		yield put(temporaryActions.setGenericError('delete'))
	} finally {
		yield put(temporaryActions.setLoading(false))
	}
}
