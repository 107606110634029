import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import cn from 'clsx'

const funky = require('../../Images/funky.png')
const forum = require('../../Images/forum.png')
const useClasses = makeStyles(theme => ({
	contact:{
		display: 'flex',
		background: 'white',
		borderRadius: '8px',
		padding: '30px 150px',
		justifyContent: 'space-between',
	},
	contactMobile:{
		flexDirection: 'column',
		width: '100%',
		padding: '0',
		alignItems: 'center',
		marginTop: '50px',
	},
	contactBoxes:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		
	},
	contactBoxesMobile:{
		width: '70%',
		marginBottom: '30px',
	},
	contactBoxesImg:{
		marginBottom: '10px',
		height: '127px',
	},
	contactBoxesImgMobile:{
		width: '100%',
		height: 'auto',
	},
	contactBoxesLink:{
		margin: '10px 0px',
		fontSize: '14pt',
		color: 'black',
		fontWeight: '700'
	}
}))

const Contact = () => {

	const classes = useClasses()
	const isMobile = useSelector(state => state.temporary.isMobile)
    return (
		<Box className={cn(classes.contact,{
            [classes.contactMobile]: isMobile
        })}>
			<Box className={cn(classes.contactBoxes,{
            [classes.contactBoxesMobile]: isMobile
        })}>
				<img className={cn(classes.contactBoxesImg,{
            [classes.contactBoxesImgMobile]: isMobile
        })} src={funky}/>
				<a className={cn(classes.contactBoxesLink,{
            [classes.contactBoxesLink]: isMobile
        })}  href="tel:0314243225">031 424 32 25</a>
				<a className={cn(classes.contactBoxesLink,{
            [classes.contactBoxesLink]: isMobile
        })} href="mailto: weare@funkycitizens.org">Trimite un e-mail</a>
			</Box>
			<Box className={cn(classes.contactBoxes,{
            [classes.contactBoxesMobile]: isMobile
        })}>
				<img className={cn(classes.contactBoxesImg,{
            [classes.contactBoxesImgMobile]: isMobile
        })} src={forum}/>
				<a className={cn(classes.contactBoxesLink,{
            [classes.contactBoxesLink]: isMobile
        })} href="tel:0772270593">0772 270 593</a>
				<a className={cn(classes.contactBoxesLink,{
            [classes.contactBoxesLink]: isMobile
        })} href="mailto: contact@forumapulum.ro">Trimite un e-mail</a>
			</Box>
		</Box>
	)
}

export default Contact
