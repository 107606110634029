import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ContentEditor from 'react-rte'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { eventsActions, temporaryActions } from '../../../Actions'
import cn from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import RetryIcon from '@material-ui/icons/SyncProblem'
import SuccessIcon from '@material-ui/icons/ThumbUpAlt'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import AddIcon from '@material-ui/icons/AddBox'
import CloseIcon from '@material-ui/icons/HighlightOff'
import ClearIcon from '@material-ui/icons/ClearAll'

const musicThumb = require('../../../Images/thumbmusic.png')
const videoThumb = require('../../../Images/thumbvideo.png')

const useClasses = makeStyles(theme => ({
	formInputs: {
		display: 'flex',
		flexDirection: 'column',
	},
	input: {
		marginBottom: 10,
	},
	rightBoxBottom:{
		marginTop: 'auto',
		height: '100%',
		width: '50%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	rightBoxBottomButton:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: 'calc(25% - 5px)',
		height: '100%',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '7.5pt',
		padding: '4px',
	},
	schimbaMedia:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: '200px',
		height: '45px',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '7.5pt',
		padding: '4px',
		marginBottom: '20px',

	},
	stergeProtest:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: 'calc(25% - 5px)',
		height: '100%',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '7.5pt',
		padding: '4px',
		background: '#ff4646',
		'&:hover':{
			background: '#e63636',
		}
	},
	error: {
		color: theme.palette.error.main,
	},
	thumb: {
		height: '100%',
		objectFit: 'cover',
		borderRadius: theme.shape.borderRadius,
		// margin: 3,
	},
	thumbBox: {
		position: 'relative',
		margin: '5px',
		borderRadius: '8px',
		overflow: 'hidden',
		height: '120px',
		cursor: 'pointer',
	},
	deleteMedia: {
		position: 'absolute',
		right: -20,
		top: -20,
		color: 'red',
	},
	featured: {
		border: '4px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: '8px',
	},
	panelContainer:{
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	toolbar:{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '45px',
		marginBottom: '15px',
	},
	panelEditable:{
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		height: 'calc(100% - 45px)',
		width: '100%',
	},
	boldSubtitle:{
		fontSize: '13pt',
		fontWeight: '700',
		margin: '15px 0px',
	},
	numeArticol:{
		fontWeight: '900',
		color: theme.palette.primary.main
	},
	tryout:{
		color: theme.palette.primary.main
	},
	continutArticolText:{
		fontSize: '12pt',
		fontWeight: '700',
		margin: '15px 0px',
	},
	mediaBox:{
		marginBottom: '20px',
	},
	backdrop: {
		position: 'absolute',
		top: -10,
		right: -10,
		left: -10,
		bottom: -10,
		zIndex: theme.zIndex.drawer + 1,
	}
}))

// const toolbarConfig = {
// 	display: ['INLINE_STYLE_BUTTONS','LINK_BUTTONS'],
// 	INLINE_STYLE_BUTTONS: [
// 		{label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
// 		{label: 'Italic', style: 'ITALIC'},
// 		{label: 'Underline', style: 'UNDERLINE'},
// 	],
// }

const articolGol = {
	nume_meniu: '',
	linie1_data_meniu: '',
	linie2_data_meniu: '',
	titlu_articol: '',
	abstract_articol: '',
	continut_articol: ContentEditor.createEmptyValue(),
	media_articol: [],
	featured: -1,
}

const music = ["mp3","wav","aif","mid","midi","mpa","ogg","wma"]
const video = ["3gp","avi","flv","h264","m4v","mp4","mkv","mov","mpg","mpeg","rm","swf","wmv","webm"]

const getThumb = extension => {
	if(music.includes(extension)) return musicThumb
	if(video.includes(extension)) return videoThumb
	return false
}

const Form = ({ editArticol, closeEdit, setEdit, onDelete }) => {

	const dispatch = useDispatch()
	const [articol, setArticol] = useState(articolGol)
	const [mediaFiles, setMediaFiles] = useState([])
	const [blobs, setBlobs] = useState([])
	const classes = useClasses()
	const { error, isLoading, upload, uploadFinished } = useSelector(state => state.temporary)
	const files = React.useRef()

	const setContent = continut_articol => {
		setArticol({ ...articol, continut_articol })
	}

	const editField = value => e => {
		setArticol({
			...articol,
			[value]: e.target.value,
		})
	}

	const saveProtest = () => {
		const articolNou = {
			...articol,
			continut_articol: articol.continut_articol.toString('html'),
			media_articol: mediaFiles,
		}
		dispatch(eventsActions.addEvent(articolNou))
	}

	const cleanForm = () => {
		setArticol(articolGol)
		setMediaFiles([])
		setBlobs([])
	}

	const onSelectFiles = e => {
		const newMedia = Array.from(e.target.files)
		if(!newMedia.length) return
		const newBlobs = Array.from(e.target.files).map(file => URL.createObjectURL(file))
		setBlobs(newBlobs)
		setMediaFiles(newMedia)
		setFeatured(0)
	}

	const onMediaDelete = index => {
		setBlobs(b => b.filter((blob, i) => i !== index))
		setMediaFiles(m => m.filter((media, i) => i !== index))
		setArticol({
			...articol,
			featured: 0,
		})
	}

	const onUpload = () => {
		files.current.click()
	}

	const onRetry = data => {
		dispatch(eventsActions.retryUploadMedia(data))
	}

	const setFeatured = index => {
		setArticol({
			...articol,
			featured: index,
		})
	}

	useEffect(() => {
		if(editArticol) {
			setArticol({
				...editArticol,
				continut_articol: ContentEditor.createValueFromString(editArticol.continut_articol, 'html'),
			})
			setBlobs(editArticol.media.map(media => '/media/' + media))
			setMediaFiles(editArticol.media)
		} else {
			cleanForm()
		}
	},[editArticol])

	useEffect(() => {
		if(uploadFinished) {
			closeEdit()
			dispatch(temporaryActions.setTemporaryValue({uploadFinished: false}))
		}
	},[uploadFinished])

	return (
		<Box className={classes.panelContainer}>
			<Box className={classes.toolbar}>
				<Button
						classes={{root: classes.rightBoxBottomButton}}
						variant="contained"
						size="small"
						onClick={closeEdit}
						startIcon={<CloseIcon />}
						>
						Închide formular
				</Button>
				<Button
						classes={{root: classes.rightBoxBottomButton}}
						variant="contained"
						size="small"
						onClick={cleanForm}
						startIcon={<ClearIcon />}
						>
						Golește formular
				</Button>
				{ articol.id && (
					<Button
						classes={{root: classes.stergeProtest}}
						variant="contained"
						size="small"
						onClick={onDelete}
						startIcon={<DeleteIcon />}
						>
						Șterge protest
					</Button>
				)}
				<Button
					classes={{root: classes.rightBoxBottomButton}}
					variant="contained"
					color="primary"
					size="small"
					onClick={saveProtest}
					startIcon={articol.id ? <EditIcon /> : <AddIcon />}
					>
					{articol.id ? "Salvează modificări" : "Adaugă protest"}
				</Button>
			</Box>

			<Box className={classes.panelEditable}>
				{articol.id ? (
					<Typography className={classes.boldSubtitle}>
						Modificare conținut pentru <span className={classes.numeArticol}>{articol.nume_meniu}</span>
					</Typography>
				) : (
					<Typography className={classes.boldSubtitle}>Adăugare articol nou</Typography>
				)}

				{ error.event && (
					<Typography className={classes.error}>Eroare de server, reîncercați</Typography>
				)}

				<Box className={classes.formInputs}>
					<TextField
					classes={{outlineColor: classes.tryout,outline: classes.outlineButton}}
						className={classes.input}
						error={error.nume_meniu}
						label="Denumire protest meniu"
						variant="outlined"
						value={articol.nume_meniu}
						onChange={editField('nume_meniu')}
					/>
					<TextField
						className={classes.input}
						error={error.linie1_data_meniu}
						label="Linie 1 data meniu"
						variant="outlined"
						value={articol.linie1_data_meniu}
						onChange={editField('linie1_data_meniu')}
					/>
					<TextField
						className={classes.input}
						error={error.linie2_data_meniu}
						label="Linie 2 data meniu"
						variant="outlined"
						value={articol.linie2_data_meniu}
						onChange={editField('linie2_data_meniu')}
					/>
					<TextField
						className={classes.input}
						error={error.titlu_articol}
						label="Titlu articol"
						variant="outlined"
						value={articol.titlu_articol}
						onChange={editField('titlu_articol')}
					/>
					<TextField
						className={classes.input}
						error={error.abstract_articol}
						label="Abstract articol"
						variant="outlined"
						value={articol.abstract_articol}
						onChange={editField('abstract_articol')}
					/>
				</Box>

				<Typography className={cn(classes.continutArticolText,{
					[classes.error]: error.continut_articol
				})}>Conținut articol</Typography>

				<ContentEditor
					value={articol?.continut_articol}
					onChange={setContent}
					// toolbarConfig={toolbarConfig}
				/>

				<Box className={classes.uploadBox}>
					<input
						multiple
						type="file"
						onChange={onSelectFiles}
						ref={files}
						accept="image/*,video/*,audio/*"
						hidden
					/>
					<Typography className={classes.boldSubtitle}>Fișiere media</Typography>
					<Box className={classes.mediaBox} display="flex" flexDirection="row" flexWrap="wrap">
						{ blobs.map((blob, index) => {
							const extension = mediaFiles[index]?.type ? mediaFiles[index]?.type.split('/').pop() : blob.split('/').pop()
							const onDelete = () => {
								onMediaDelete(index)
							}
							const onFeatured = () => {
								setFeatured(index)
							}
							const onRetryInternal = () => {
								onRetry({
									index: index,
									file: mediaFiles[index],
									id: articol.id,
									featured: articol.featured === index,
								})
							}
							return (
								<Box
									className={cn(classes.thumbBox,{[classes.featured]: index === articol.featured})}
									onClick={onFeatured}
									>
									{ !upload[index] && (mediaFiles[index] instanceof File) && (
										<IconButton
											onClick={onDelete}
											className={classes.deleteMedia}
											>
										<DeleteIcon />
									</IconButton>
									)}
									{ upload[index] === "error" &&(
										<IconButton
											onClick={onRetryInternal}
											className={classes.deleteMedia}
											>
										<RetryIcon />
									</IconButton>
									)}
									{ upload[index] === "success" && (
										<IconButton
											className={classes.deleteMedia}
											>
											<SuccessIcon />
										</IconButton>
									)}
									{ upload[index] === "loading" && (
										<IconButton
											className={classes.deleteMedia}
											>
											<CircularProgress />
										</IconButton>
									)}
									<img
										key={index}
										className={classes.thumb}
										src={getThumb(extension) || blob}
										alt="thumb"
									/>
								</Box>
							)
						})}
					</Box>
					<Button
						classes={{root: classes.schimbaMedia}}
						variant="contained"
						size="small"
						color="primary"
						onClick={onUpload}
						startIcon={<PhotoLibraryIcon />}
						>
						{ mediaFiles.length ? "Schimbă media" : "Adaugă media"}
					</Button>
				</Box>
			</Box>
			<Backdrop open={isLoading} className={classes.backdrop}>
				<CircularProgress color="primary"/>
			</Backdrop>
		</Box>
	)

}

export default Form
