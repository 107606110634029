import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useParams, Link, useLocation } from 'react-router-dom'
import cn from 'clsx'

const useClasses = makeStyles(theme => ({
	protestLink:{
		width: '100%',
		cursor: 'pointer',
		height: '55px',
		marginBottom: '2px',
	},
	protestLinkMobile:{
		height: '45px',
	},
	protestLinkActiv:{
		backgroundColor: theme.palette.primary.main + ' !important',
		color: 'white !important',
	},
	dayContainer: {
		background: 'transparent',
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		zIndex: 1
	},
	dayLeftContainer: {
		display: 'flex',
		flexDirection: 'row',
		background: 'black',
		minWidth: '54px',
		height: '100%',
		width: '20%',
		marginRight: '2px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderRadius: '8px',
		overflow: 'hidden',
		background: 'black',
		borderRadius: '6px',
		padding: '8px',
	},
	dayLeftContainerMobile:{
		width: '35%',
	},
	dayRightContainerMobile:{
		width: '65%',
	},
	dayNumber: {
		color: 'white',
		fontWeight: '600',
		fontSize: '8.5pt',
		marginRight: '5px'
	},
	dayMonth: {
		color: 'white',
		fontSize: '8.5pt',
		fontWeight: '600',
	},
	dayRightContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '80%',
		height: '100%',
		borderRadius: '6px',
		background: '#404040',
	},
	dayName: {
		color: 'white',
		fontWeight: '800',
		width: '100%',
		fontSize: '11.2pt',
		textTransform: 'uppercase',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: '5px',
		padding: '15px'
	},
	dayNameActiv:{
		color: 'white',
	},
	dayRightImageContainer: {
		height: '100%',
		width: '37%',
	},
	dayRightImage: {
		height: '100%',
		width: '100%',
		borderTopRightRadius: '6px',
		borderBottomRightRadius: '6px',
		objectFit: 'cover',
	},
	daySelected: {
		// background: '#fed130',
		// color: 'black',
	},
	arrowBoxImg: {
		paddingTop: '14px',
		paddingLeft: '2px',
		paddingRight: '2px',
		position: 'absolute',
		height: '48px',
		left: '416px',
		transition: 'all ease-in-out'
	},

}))

const ProtestMenu = ({event:{ id, linie1_data_meniu, linie2_data_meniu, nume_meniu }, disabled}) => {

	const dispatch = useDispatch()
	// const state = useSelector(state => state)
	const classes = useClasses()
	const { protestId } = useParams()
	const location = useLocation()
	const pathname = location.pathname.split('/')[1]
	const showArrow = Boolean(nume_meniu === protestId)
	const isMobile = useSelector(state => state.temporary.isMobile)
	return (
		<Link to={!disabled && '/' + pathname + '/' + nume_meniu}  className={cn(classes.protestLink,{
			[classes.protestLinkMobile]: isMobile
		})}>
			<Box className={classes.dayContainer}>

				<Box className={cn(classes.dayLeftContainer,{
					[classes.protestLinkActiv]: showArrow,
					[classes.dayLeftContainerMobile]: isMobile
				})}>
					<span className={classes.dayNumber}>{linie1_data_meniu}</span>
					<span className={classes.dayMonth}>{linie2_data_meniu}</span>
				</Box>

				<Box className={cn(classes.dayRightContainer,{
					[classes.protestLinkActiv]: showArrow,
					[classes.dayRightContainerMobile]: isMobile
				})}>

					<span className={cn(classes.dayName,{
			[classes.dayNameActiv]: showArrow
		})}>{nume_meniu}</span>
				</Box>
			</Box>
		</Link>
	)

}

export default ProtestMenu
