import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MenuElement from '../../Elements/ProtestMenu'
import ViewEvent from '../../Elements/ViewEvent'
import { useParams, useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { eventsActions } from '../../Actions'
import cn from 'clsx'

const useClasses = makeStyles(theme => ({
	Panel: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
	},
	leftBox: {
		width: 'calc(40% - 5px)',
		// border: '1px solid blue',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		overflow: 'hidden',
		padding: 10,
		borderRadius: 14,
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
	},
	rightBox: {
		width: 'calc(60% - 5px)',
		height: '100%',
		// border: '1px solid red',
		borderRadius: 14,
		padding: 10,
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	listEventsBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	listEventsMobile: {
		width: '100%!important',
		padding: '11px',
	},
	delete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		"&:hover": {
			backgroundColor: theme.palette.error.dark,
		}
	},
	leftBoxBottom:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: '100%',
		height: '50px',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
	},
	rightBoxBottom:{
		marginTop: 'auto',
		height: '50px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	rightBoxBottomButton:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: 'calc(50% - 5px)',
		height: '50px',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
	},
	protestWraper1:{
		overflow: 'hidden',
		height: '100%'
	},
	eventsWrapper:{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: '1366px',
		// padding: '10px 50px',
		width: '100%',
		height: '100%',
		overflow: 'hidden'

	},
	eventsList:{
		width: 'calc(50% - 5px)',
		overflowX: 'auto',
	},
	eventArticle:{
		width: 'calc(50% - 5px)',
		borderRadius: '14px',
		display: 'flex',
	},
	innerWrapperCOLLAPSE:{
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	bigWrapper:{
		width: '100%',
		height: '100%',
		display: 'flex',
	},
}))

const Events = () => {

	const classes = useClasses()
	const { protestId } = useParams()
	const history = useHistory()
	const [event, setEvent] = useState()
	const events = useSelector(state => state.events)
	const isMobile = useSelector(state => state.temporary.isMobile)

	useEffect(() => {
		protestId && setEvent(events.find(protest => protest.nume_meniu === protestId))
		!protestId && events[0] && !isMobile && history.replace('/proteste/' + events[0].nume_meniu)
		if(!protestId && isMobile) {
			setEvent(null)
		}
	},[protestId, events, isMobile])

	return !isMobile ? (
		<Box className={classes.eventsWrapper}>
			<Box className={classes.eventsList}>
				<Box className={classes.listEventsBox}>
					{events.map(event => {
						return <MenuElement
							key={event.id}
							event={event}
						/>
					})}
				</Box>
			</Box>

			{/* <Box className={classes.eventArticle}> */}
				<Collapse classes={{wrapperInner: classes.innerWrapperCOLLAPSE, wrapper: classes.bigWrapper}} className={classes.eventArticle} in unmountOnExit timeout={{appear: 250, enter:250, exit: 0}}>
					<ViewEvent
						event={event}
					/>
				</Collapse>
			{/* </Box> */}
		</Box>
	) : (
		<Box className={classes.eventsWrapper}>
			<Box className={cn(classes.eventsList,classes.listEventsMobile)}>
				<Box className={classes.listEventsBox}>
					{events.map(event => {
						return <MenuElement
							key={event.id}
							event={event}
						/>
					})}
				</Box>
			</Box>
			{ event && <ViewEvent event={event} />}
		</Box>
	)

}

export default Events
