import { actionType } from '../Constants'

const initialState = {
	token: undefined,
	error: false,
	isLoading: false,
}

export default (state = initialState, action) => {
	switch(action.type) {
		case actionType.SET_TOKEN:
			return {
				...state,
				token: action.token
			}
		case actionType.SET_LOGIN_ERROR:
			return {
				...state,
				error: action.error,
			}
		case actionType.SET_LOADING:
			return {
				...state,
				isLoading: action.status,
			}
		case actionType.DO_LOGOUT:
			return initialState
		default:
			return state
	}
}
