import React, {useState, useEffect} from 'react'
import useClasses from './Header.classes'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Menu from './Elements/Menu'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
const logoImg = require('../../Images/logo.png')
export default () => {

	const [menu, setMenu] = useState(false)
	const classes = useClasses()

	const onClickAway = () => {
		menu && setMenu(false)
	}

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const toggleMenu = () => {
		setMenu(prevMenu => !prevMenu)
	}

	useEffect(() => {
		!isMobile && onClickAway()
	},[isMobile])

    return (
		<>
		<ClickAwayListener onClickAway={onClickAway} touchEvent={false}>
			  <AppBar position="fixed" color="secondary" className={classes.colorHeader}>
				   <Toolbar className={classes.mainBox} disableGutters={true}>
					   	<Link to="/proteste" className={classes.siteLogo}>
							<img
								className={classes.siteLogoImg}
								src={logoImg}
								alt="logo"
							/>
						</Link>


						{ !isMobile && <Menu close={onClickAway} />}

						{ isMobile &&
							<IconButton
								className={classes.menuButton}
								edge="start"
								color="primary"
								onClick={toggleMenu}
							>
							{ menu
								? <CloseRoundedIcon fontSize="large" />
								: <MenuRoundedIcon fontSize="large" />
							}
							</IconButton>
						}
					   </Toolbar>
					   { isMobile &&
						<Collapse in={menu}>
   							<Menu close={onClickAway} />
   						</Collapse>
					   }
				</AppBar>

			</ClickAwayListener>
			<Box className={classes.spacer} />
		</>
	)
}
