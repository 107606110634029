import React, { memo, Fragment } from 'react'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import { Link, useRouteMatch } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import useClasses from '../Header.classes'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import cn from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { useSelector, useDispatch } from 'react-redux'
import { loginActions } from '../../../Actions'

const dec89logo = require('../../../Images/dec89.png')
const Dec89Icon = memo(({ classes }) => (
	<img
		src={dec89logo}
		className={classes.root}
		alt="dec89 icon"
	/>
))

const menuElements = [
	{
		title: 'Proteste',
		route: '/proteste',
		Icon: classes => <InfoOutlinedIcon {...classes} />,
	},

	{
		title: 'Contact',
		route: '/contact',
		Icon: classes => <MailOutlinedIcon {...classes} />,
	},
	{
		title: 'Dec89',
		mobileTitle: 'decembrie89.ro',
		route: 'https://decembrie89.ro/',
		Icon: classes => <Dec89Icon {...classes} />,
		extern: true,
	},
]

const ListElements = memo(({ close }) => {

	const theme = useTheme()
	const classes = useClasses()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))


	return menuElements.map(({ title, route, Icon, activeOnlyWhenExact, extern, mobileTitle }) => {

		const match = useRouteMatch({
		  path: route,
		  exact: activeOnlyWhenExact
		})

		return (
			<Link to={{pathname: route}} key={title} target={extern && "_blank"}>
				<ListItem button onClick={close} classes={{
					button: classes.menuButton,
					root: cn({[classes.menuLinkTextActive]: match})
				}}>

				{ isMobile &&
					<ListItemIcon>
						<Icon classes={{ root: classes.menuLinkIcon }} />
					</ListItemIcon>
				}

				<ListItemText primary={
					<Typography component="h4" className={classes.menuLinkText}>
						{(extern && isMobile) ? mobileTitle : title}
					</Typography>
				}/>
				</ListItem>
			</Link>
		)
	})
})

export default memo(({ close }) => {

	const theme = useTheme()
	const classes = useClasses()
	const dispatch = useDispatch()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const token = useSelector(state => state.login.token)
	const match = useRouteMatch({
	  path: '/admin',
	  exact: false
	})

	const doLogout = () => {
		dispatch(loginActions.doLogout())
	}

	return (
		<List
			disablePadding
			component="nav"
			className={cn(classes.menu,{
				[classes.menuDesktop]: !isMobile
			})}
			>
			{ token && !isMobile && (
				<Box className={classes.adminMenu}>
					<Link to="/admin">
						<ListItem button classes={{
							button: classes.menuButton,
							root: cn({[classes.menuLinkTextActive]: match})
						}}>
						<ListItemText primary={
							<Typography component="h4" className={classes.menuLinkText}>
								Admin
							</Typography>
						}/>
						</ListItem>
					</Link>
					<ListItem button onClick={doLogout} classes={{
						button: classes.menuButton,
					}}>

					<ListItemText primary={
						<Typography component="h4" className={classes.menuLinkText}>
							Logout
						</Typography>
					}/>
					</ListItem>
				</Box>
			)}
			<ListElements close={close}/>
		</List>
	)
})
