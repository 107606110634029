import { actionType } from '../Constants'

export const loginActions = {
	doLogin: password => ({
		type: actionType.DO_LOGIN_SAGA,
		password: password,
	}),
	doLogout: () => ({
		type: actionType.DO_LOGOUT,
	}),
	setError: status => ({
		type: actionType.SET_LOGIN_ERROR,
		error: status,
	}),
	setToken: token => ({
		type: actionType.SET_TOKEN,
		token: token,
	})
}

export const eventsActions = {
	getEvents: () => ({
		type: actionType.GET_EVENTS_SAGA,
	}),
	addEvent: event => ({
		type: actionType.ADD_EVENT_SAGA,
		event: event,
		edit: false,
	}),
	editEvent: event => ({
		type: actionType.ADD_EVENT_SAGA,
		event: event,
		edit: true,
	}),
	deleteEvent: (id, setDelete) => ({
		type: actionType.DELETE_EVENT_SAGA,
		id: id,
		setDelete: setDelete,
	}),
	setEvents: events => ({
		type: actionType.SET_EVENTS,
		events: events,
	}),
	uploadMediaFile: file => ({
		type: actionType.UPLOAD_MEDIA_EVENT_SAGA,
		file: file,
	}),
	retryUploadMedia: data => ({
		type: actionType.RETRY_UPLOAD_MEDIA_SAGA,
		...data,
	})
}

export const temporaryActions = {
	setLoading: status => ({
		type: actionType.SET_LOADING,
		status: status,
	}),
	setGenericError: error => ({
		type: actionType.SET_GENERIC_ERROR,
		error: error,
	}),
	setGenericErrors: error => ({
		type: actionType.SET_GENERIC_ERRORS,
		error: error,
	}),
	setTemporaryValue: value => ({
		type: actionType.SET_TEMPORARY_VALUE,
		value: value,
	}),
	setUploadStatus: (index, status) => ({
		type: actionType.SET_LOADING_STATUS_UPLOAD,
		index: index,
		status: status,
	}),
	setMobile: status => ({
		type: actionType.SET_MOBILE,
		status: status,
	})
}
