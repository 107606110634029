import { actionType } from '../Constants'

const initialState = {
	error: {},
	isLoading: false,
	appLoaded: false,
	upload: {

	},
	uploadFinished: false,
	isMobile: false,
}

export default (state = initialState, action) => {
	switch(action.type) {
		case actionType.SET_LOADING:
			return {
				...state,
				isLoading: action.status
			}
		case actionType.SET_GENERIC_ERROR:
			return {
				...state,
				error: {
					[action.error]: true,
				},
			}
		case actionType.SET_GENERIC_ERRORS:
			return {
				...state,
				error: action.error,
			}
		case actionType.SET_TEMPORARY_VALUE:
			return {
				...state,
				...action.value,
			}
		case actionType.SET_MOBILE:
			return {
				...state,
				isMobile: action.status,
			}
		case actionType.SET_LOADING_STATUS_UPLOAD:
			return {
				...state,
				upload: {
					...state.upload,
					[action.index]: action.status,
				}
			}
		default:
			return state
	}
}
