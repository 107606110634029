import { actionType } from '../Constants'

const initialState = []

export default (state = initialState, action) => {
	switch(action.type) {
		case actionType.SET_EVENTS:
			return action.events
		default:
			return state
	}
}
