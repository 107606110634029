import {take, call, put, delay, select,takeLatest} from 'redux-saga/effects'
import { actionType } from '../Constants'
import { eventsActions, temporaryActions } from '../Actions'
import { API } from './common'
import axios from 'axios'

export default function* eventsSaga(){
	while (true){
		yield take(actionType.GET_EVENTS_SAGA)
		yield call(getEvents)
	}
}

function* getEvents(){
	try {
		yield put(temporaryActions.setLoading(true))
		const { data } = yield axios.post(API + 'events/')

		yield put(eventsActions.setEvents(data.events))

	} catch (error) {
		yield delay(2000)
		yield put(eventsActions.getEvents())
	} finally {
		yield put(temporaryActions.setLoading(false))
		yield put(temporaryActions.setTemporaryValue({
			appLoaded: true
		}))
	}
}
