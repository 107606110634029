import { createMuiTheme } from '@material-ui/core/styles'

const theme = {
    "breakpoints": {
        "keys": ["xs", "sm", "md", "lg", "xl"],
        "values": {
            "xs": 0,
            "lg": 1280,
            "sm": 850,
            "xl": 1920,
            "md": 1032
        }
    },
    "mixins": {
        "toolbar": {
                "minHeight": 56
        }
    },
    "shadows": [
        "none",
        "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
        "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
        "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
        "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
        "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
        "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
        "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
        "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
        "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
        "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
        "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
        "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
        "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
        "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
        "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
        "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
        "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
        "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
        "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
    ],
    "direction": "ltr",
    "overrides": {},
    "transitions": {
        "easing": {
            "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
            "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
            "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
            "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
        },
        "duration": {
            "standard": 300,
            "short": 250,
            "enteringScreen": 225,
            "shorter": 200,
            "leavingScreen": 195,
            "shortest": 150,
            "complex": 375
        }
    },
    "typography": {
        "headline": {
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.35417em",
            "fontSize": "1.5rem",
            "fontWeight": 400
        },
        "display2": {
            "marginLeft": "-.02em",
            "color": "rgba(0, 0, 0, 0.54)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.13333em",
            "fontSize": "2.8125rem",
            "fontWeight": 400
        },
        "fontWeightLight": 300,
        "display3": {
            "marginLeft": "-.02em",
            "color": "rgba(0, 0, 0, 0.54)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "letterSpacing": "-.02em",
            "lineHeight": "1.30357em",
            "fontSize": "3.5rem",
            "fontWeight": 400
        },
        "display4": {
            "marginLeft": "-.04em",
            "color": "rgba(0, 0, 0, 0.54)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "letterSpacing": "-.04em",
            "lineHeight": "1.14286em",
            "fontSize": "7rem",
            "fontWeight": 300
        },
        "fontWeightRegular": 400,
        "display1": {
            "color": "rgba(255, 255, 2555, 0.75)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.20588em",
            "fontSize": "2.125rem",
            "fontWeight": 400
        },
        "button": {
            "textTransform": "uppercase",
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "fontSize": "0.875rem",
            "fontWeight": 500
        },
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "body2": {
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.71429em",
            "fontSize": "0.875rem",
            "fontWeight": 500
        },
        "caption": {
            "color": "rgba(0, 0, 0, 0.54)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.375em",
            "fontSize": "0.75rem",
            "fontWeight": 400
        },
        "fontSize": 14,
        "fontWeightMedium": 500,
        "title": {
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.16667em",
            "fontSize": "1.3125rem",
            "fontWeight": 500
        },
        "subheading": {
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.5em",
            "fontSize": "1rem",
            "fontWeight": 400
        },
        "body1": {
            "color": "rgba(0, 0, 0, 0.87)",
            "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
            "lineHeight": "1.46429em",
            "fontSize": "0.875rem",
            "fontWeight": 400
        }
    },
    "zIndex": {
        "modal": 1300,
        "snackbar": 1400,
        "drawer": 1200,
        "appBar": 1100,
        "mobileStepper": 1000,
        "tooltip": 1500
    },
    "shape": {
        "borderRadius": 4
    },
    "props": {},
    "spacing": 4,
	"palette": {
        "common": {
            "black": "rgba(45, 45, 45, 1)",
            "white": "rgba(250, 250, 250, 1)"
        },
        "background": {
            "paper": "#fafafa",
            "default": "rgba(133, 133, 133, 1)"
        },
        "primary": {
			"light": "#69d0ff",
            "main": "#009fff",
            "dark": "#0071cb",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "#2c2c2c",
            "main": "#000",
            "dark": "#000",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(0, 0, 0, 1)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    },
    "themeName": "Proteste"
}

const proteste = createMuiTheme({
  ...theme
})

export default proteste
