import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import cn from 'clsx'

const useClasses = makeStyles(theme => ({
    subHeaderContainer:{
        width: '100%',
        height: '100px',
        background: theme.palette.primary.main,
    },
    subHeader:{
        maxWidth: '1200px',
        width: '100%',
        height: '100%',
        margin: '0 auto',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    leftText:{
        fontSize: '17pt',
        color: 'white',
        fontWeight: '900',
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'uppercase',
        minWidth: '156px',
    },
    rightText:{
        fontSize: '8pt',
        color: 'white',
        fontWeight: '700',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '50px',
    },
    // Mobile from here down
    subHeaderContainerMobile:{
        height: 'auto',
        background: 'white',
    },
    subHeaderMobile:{
        flexDirection: 'column',
        padding: '0',
    },
    leftTextMobile:{
        width: '100%',
        background: theme.palette.primary.main,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
        fontSize: '7.4vw',
        padding: '8px 0px',
        fontWeight: '800'
    },
    rightTextMobile:{
        fontSize: '8pt',
        color: 'black',
        marginLeft: '0',
        padding: '5px 11px 0px 11px',
    }
}))

const SubHeader = () => {

	const classes = useClasses()
	const isMobile = useSelector(state => state.temporary.isMobile)

	return (
		<Box className={cn(classes.subHeaderContainer,{
	            [classes.subHeaderContainerMobile]: isMobile
	        })}>
            <Box className={cn(classes.subHeader,{
	            [classes.subHeaderMobile]: isMobile
	        })}>
	            {!isMobile ? (
					<Typography className={classes.leftText}>
						Proteste
						<span className={classes.leftText}>în România</span>
					</Typography>
				) : (
					<Typography className={cn(classes.leftText, classes.leftTextMobile)}>
						Proteste în România
					</Typography>
				)}
                <Typography className={cn(classes.rightText,{[classes.rightTextMobile]: isMobile})}>
                	Protestul (pașnic) este o formă legitimă prin care cetățenii pot să-și exprime nemulțumirea față de unele decizii sau atitudini ale autorităților. Încă din 1990, în România au avut loc mai multe acțiuni de protest, pe teme legate de drepturi politice, mediu, măsuri economice sau corupție. Mai jos sunt prezentate cele mai importante manifestații de protest, punând în evidență contextul dar și, în anumite cazuri, dezinformările care au însoțit evenimentele.
                </Typography>
            </Box>
        </Box>
	)

}

export default SubHeader
