import { actionType } from '../Constants'
import { temporaryActions } from '../Actions'

export default ({ dispatch, getState }) => next => action => {

	if(action.type === actionType.ADD_EVENT_SAGA) {
		let error = {}
		const { nume_meniu, linie1_data_meniu, titlu_articol, abstract_articol, continut_articol } = action.event
		if(nume_meniu.length < 4) error.nume_meniu = true
		if(linie1_data_meniu.length < 1) error.linie1_data_meniu = true
		if(titlu_articol.length < 4) error.titlu_articol = true
		if(abstract_articol.length < 10) error.abstract_articol = true
		if(continut_articol.length < 100) error.continut_articol = true

		dispatch(temporaryActions.setGenericErrors(error))

		if(Object.keys(error).length > 0) return
	}

	return next(action)
}
