import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Panel from './Elements/Panel'
import Login from './Elements/Login'

const useClasses = makeStyles(theme => ({
	Admin: {
		height: '100%',
	}
}))

const Admin = () => {

	const token = useSelector(state => state.login.token)
	const classes = useClasses()

	return (
		<Box className={classes.Admin}>
			{ !token && <Login /> }
			{ token && <Panel /> }
		</Box>
	)

}



export default Admin
