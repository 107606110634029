import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import temporaryReducer from './temporaryReducer'
import eventsReducer from './eventsReducer'

const reducers = combineReducers({
	login: loginReducer,
	temporary: temporaryReducer,
	events: eventsReducer,
})

export default reducers
