import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MenuElement from '../../../Elements/ProtestMenu'
import ViewEvent from '../../../Elements/ViewEvent'
import { useParams, useHistory } from 'react-router-dom'
import FormEvent from './Form'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { eventsActions, temporaryActions } from '../../../Actions'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const useClasses = makeStyles(theme => ({
	Panel: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: '1200',
		width: '100%',
		height: '100%',
	},
	leftBox: {
		width: 'calc(40% - 5px)',
		// border: '1px solid blue',
		height: '100%',
		width: 'calc(40% - 5px)',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		overflow: 'hidden',
		padding: 10,
		borderRadius: 14,
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		overflowX: 'auto',
		background: 'white',
	},
	rightBox: {
		width: 'calc(60% - 5px)',
		height: '100%',
		// border: '1px solid red',
		borderRadius: 14,
		padding: 10,
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		overflow: 'hidden',
		background: 'white',
	},
	listEventsBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	delete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		"&:hover": {
			backgroundColor: theme.palette.error.dark,
		}
	},
	leftBoxBottom:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: '100%',
		height: '50px',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
	},
	rightBoxBottom:{
		marginTop: 'auto',
		height: '50px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	rightBoxBottomButton:{
		boxShadow: '0px 0px 6px rgba(0,0,0,0.21)',
		width: 'calc(50% - 5px)',
		height: '50px',
		borderRadius: 14,
		display:'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 'bold',
	},
	protestWraper1:{
		overflowX: 'hidden',
		overflowY: 'auto',
		height: 'calc(100% - 70px)'
	},
	error: {
		color: theme.palette.error.main,
	},
	innerWrapperCOLLAPSE:{
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	bigWrapper:{
		width: '100%',
		height: '100%',
		display: 'flex',
	},
}))

const Panel = () => {

	const classes = useClasses()
	const { protestId } = useParams()
	const [event, setEvent] = useState()
	const dispatch = useDispatch()
	const [edit, setEdit] = React.useState(false)
	const events = useSelector(state => state.events)
	const errors = useSelector(state => state.temporary.error)
	const [openDelete, setDelete] = React.useState(false)
	const history = useHistory()

	const addEvent = () => {
		history.push('/admin')
		setEvent(null)
		setEdit(true)
	}

	const onEdit = () => {
		setEdit(true)
	}

	const closeEdit = () => {
		setEdit(false)
	}

	const confirmDelete = () => {
		setDelete(true)
	}

	const onDelete = () => {
		dispatch(eventsActions.deleteEvent(event.id, setDelete))
	}

	const cancelDelete = () => {
		setDelete(false)
		dispatch(temporaryActions.setGenericErrors({}))
	}

	useEffect(() => {
		protestId && setEvent(events.find(protest => protest.nume_meniu === protestId))
		!protestId && event !== null && events[0] && history.replace('/admin/' + events[0].nume_meniu)
		if(!events[0]?.id) {
			history.replace('/admin')
			setEvent(null)
		}
	},[protestId, events])

	return (
		<Box className={classes.Panel}>
			<Box className={classes.leftBox}>
				<Box className={classes.listEventsBox}>
					{events.map(event => {
						return <MenuElement
							key={event.id}
							event={event}
							disabled={edit}
						/>
					})}
				</Box>
					<Button
					classes={{root: classes.leftBoxBottom}}
					variant="contained"
					color="primary"
					className={classes.addButton}
					onClick={addEvent}
					disabled={edit}
				>
					Adaugă protest
				</Button>

			</Box>

			<Box className={classes.rightBox}>

				<Collapse  classes={{wrapperInner: classes.innerWrapperCOLLAPSE, wrapper: classes.bigWrapper}} in={edit} unmountOnExit>
					<FormEvent
						edit={edit}
						editArticol={event}
						closeEdit={closeEdit}
						setEdit={onEdit}
						onDelete={confirmDelete}
					/>
				</Collapse>

				<Collapse className={classes.protestWraper1} in={!edit} unmountOnExi>
					<ViewEvent
						event={event}
						onEdit={onEdit}
						editable
					/>
				</Collapse>

				{ !edit && event && (
					<Box className={classes.rightBoxBottom}>
						<Button
							classes={{root: classes.rightBoxBottomButton}}
							variant="contained"
							size="small"
							onClick={confirmDelete}
							startIcon={<DeleteIcon />}
							>
							Șterge protest
						</Button>
						<Button
							classes={{root: classes.rightBoxBottomButton}}
							variant="contained"
							color="primary"
							size="small"
							onClick={onEdit}
							startIcon={<EditIcon />}
							>
							Modifică protest
						</Button>
					</Box>
				)}

				<Dialog
					open={openDelete}
					onClose={cancelDelete}
					TransitionComponent={Transition}
					>
					<DialogTitle>Ștergere protest</DialogTitle>
					<DialogContent>
						{ errors.delete && (
							<DialogContentText className={classes.error}>
								Eroare la ștergere
							</DialogContentText>
						)}
						<DialogContentText>
							Sigur vrei să ștergi protestul {event?.nume_meniu} ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={cancelDelete} color="primary">
							Anulează
						</Button>
						<Button onClick={onDelete} className={classes.delete}>
							Șterge
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Box>
	)

}

export default Panel
