import {makeStyles} from '@material-ui/core/styles'

const useClasses = makeStyles(theme => ({
	mainBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '56px',
		width: '100%',
		maxWidth: '1200px',
		minWidth: '280px',
		borderRadius: '0px',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingLeft: 10,
		paddingRight: 10,
		background: 'transparent',
		// paddingTop: '10px',
	},
	menuLinkIcon: {
		height: 24,
		width: 24,
		color: 'white',
	},
	colorHeader: { // optional
		background: 'linear-gradient(to bottom right, #000, #111, #222, #333, #444)'
	},
	spacer: theme.mixins.toolbar,
	middleGrow: {
		flexGrow: 1,
	},
	logoDesktop: {
		height: '100%',
		[theme.breakpoints.down('xs')]: {
	      display: 'none',
	    },
	},
	logoMobile: {
		height: '100%',
		[theme.breakpoints.up('sm')]: {
	      display: 'none',
	    },
	},
	menuLinkText: {
		color: theme.palette.primary.contrastText + '!important',
	},
	menu: {
		// padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('sm')]: {
	      flexDirection: 'row',
	    },
		"& > a": {
			textDecoration: 'none',
			"& :hover": {
				color: theme.palette.primary.main + '!important',
			},
		},
	},
	menuLinkTextActive: {
		"& *": {
			color: theme.palette.primary.main + '!important',
		}
	},
	menuButton: {
		[theme.breakpoints.up('sm')]: {
	      padding: '0 !important',
	    },
		marginLeft: '20px !important',
	},
	logoBox: {
		height: '0px',
		...theme.mixins.toolbar
	},
	title: {
		[theme.breakpoints.down('sm')]: {
	      paddingLeft: theme.spacing(2),
	    },
	},
	adminMenu: {
		display: 'flex',
		flexDirection: 'row',
		borderRight: '1px solid ' + theme.palette.primary.light,
		paddingRight: '20px'
	},
	siteLogo:{
		height: 'calc(100% - 10px)',
		marginTop: 'auto',
	},
	siteLogoImg: {
		// width: '100%',
		height: '100%',
	}
}))

export default useClasses
