import {take, call, put, delay, select,takeLatest} from 'redux-saga/effects'
import { actionType } from '../Constants'
import { loginActions, temporaryActions } from '../Actions'
import { API } from './common'
import axios from 'axios'

export default function* loginSaga(){
	while (true){
		const { password } = yield take(actionType.DO_LOGIN_SAGA)
		yield call(doLogin, password)
	}
}

function* doLogin(password){
	try {
		yield put(temporaryActions.setLoading(true))
		yield put(loginActions.setError(false))

		const data = new FormData()
		data.append('password', password)

		const { data: token } = yield axios.post(API + 'login/', data)

		yield put(loginActions.setToken(token))
	} catch (error) {
		yield put(loginActions.setError(true))
	} finally {
		yield put(temporaryActions.setLoading(false))
	}
}
