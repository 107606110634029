import {take, call, put, delay, select,takeLatest} from 'redux-saga/effects'
import { actionType } from '../Constants'
import { eventsActions, temporaryActions } from '../Actions'
import { API } from './common'
import axios from 'axios'

export default function* upsertSaga(){
	while (true){
		const data = yield take(actionType.ADD_EVENT_SAGA)
		yield call(addEvent, data)
	}
}

function* addEvent({ event }){
	try {
		yield put(temporaryActions.setLoading(true))

		const { token } = yield select(state => state.login.token)

		const data = new FormData()
		data.append('token', token)
		data.append('event[nume_meniu]', event.nume_meniu)
		data.append('event[linie1_data_meniu]', event.linie1_data_meniu)
		data.append('event[linie2_data_meniu]', event.linie2_data_meniu)
		data.append('event[titlu_articol]', event.titlu_articol)
		data.append('event[abstract_articol]', event.abstract_articol)
		data.append('event[continut_articol]', event.continut_articol)


		if(event.id) {
			data.append('edit', true)
			data.append('event[id]', event.id)
		}

		const { data: lastEvents } = yield axios.post(API + 'upsert/', data)

		if(event.media_articol?.length && (event.media_articol[0] instanceof File)) {

			const lastEventId = lastEvents.events[lastEvents.events.length - 1]?.id
			const id = event?.id || lastEventId

			const toDelete = new FormData()
			toDelete.append('token', token)
			toDelete.append('id', id)

			if(event.id) {
				yield axios.post(API + 'events/deleteMedia.php', toDelete)
			}
			
			for(var i = 0; i < event.media_articol.length ; i++) {
				yield uploadMedia({
					index: i,
					file: event.media_articol[i],
					id: id,
					featured: i === event.featured ? 1 : 0,
				})
			}
		} else if(event.id && event.featured !== lastEvents.events.find(oldEvent => oldEvent.id === event.id)?.featured) {
			const link = event.media_articol.find((_,i ) => event.featured === i)
			const editFeatured = new FormData()
			editFeatured.append('token', token)
			editFeatured.append('link', link)
			editFeatured.append('id', event.id)
			try {
				yield axios.post(API + 'events/changeFeatured.php', editFeatured)
			} catch(e) {
				// console.log('eroare featured', e);
			}
		}

		const { data: { events }} = yield axios.post(API + 'events/')
		yield put(temporaryActions.setTemporaryValue({uploadFinished: true,upload: {}}))
		yield put(eventsActions.setEvents(events || []))
	} catch (error) {

		// console.log('Eroare media', error)
		// console.log('Eroare media 2', error.response)
		// console.log('Eroare media 3', error.response?.data)

		yield put(temporaryActions.setGenericError('event'))
	} finally {
		yield put(temporaryActions.setLoading(false))
	}
}

export function* uploadMediaSaga() {
	while (true){
		const data = yield take(actionType.RETRY_UPLOAD_MEDIA_SAGA)
		yield call(uploadMedia, data)
	}
}

function* uploadMedia({index, file, id, featured}){
	try {
		yield put(temporaryActions.setUploadStatus(index, "loading"))

		const { token } = yield select(state => state.login.token)

		const data = new FormData()
		data.append('token', token)
		data.append('media', file)
		data.append('featured', featured)
		data.append('id', id)

		yield axios.post(API + 'upload/', data)

		yield put(temporaryActions.setUploadStatus(index, "success"))

	} catch (error) {
		// console.log('error upload',error.response);
		// console.log('Eroare upload data', error?.response?.data)
		yield put(temporaryActions.setUploadStatus(index, "error"))
	} finally {
		// etc
	}
}
