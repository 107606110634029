import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import { loginActions } from '../../../Actions'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/TextField'

const useClasses = makeStyles(theme => ({
	Login: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loginBox: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '25px',
		alignItems: 'flex-start',
		flexDirection: 'column',
		width: 350,
		height: 190,
		borderRadius: 8,
		border: '1px solid grey',
		background: theme.palette.primary.main,
		position: 'relative',
	},
	password: {
		backgroundColor: theme.palette.common.white,
		width: '100%',
		color: 'black',
	},
	stilButonLogin:{
		width: '100%',
		padding: '13px',
		fontWeight: '800',
		background: 'white',
		color: 'black',
		'&:hover':{
			background: '#efefef',
		}
	},
	notchOut:{
		border: 0,
	},
	loginEsuat:{
		position: 'absolute',
		top: 8,
		left: '50%',
		transform: 'translate(-50%, 0px)',
		color: 'red',
		background: 'white',
		borderRadius: '6px',
		padding: '3px 12px',
		fontWeight: '700',
	},
	parolaLabel:{
		fontWeight: '700',
		color: 'white',
	},
	passBox:{
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '10px',
	}
}))

const Login = () => {

	const dispatch = useDispatch()
	const state = useSelector(state => state.login)
	const classes = useClasses()
	const [password, setPassword] = useState("")

	const doLogin = () => {
		dispatch(loginActions.doLogin(password))
	}

	const onChange = e => {
		setPassword(e.target.value)
	}

	const { isLoading, error } = state

	return (
		<Box className={classes.Login}>
			<Box className={classes.loginBox}>
				{ isLoading && <Typography className={classes.loginEsuat}>Se încarcă...</Typography>}
				{ error && <Typography className={classes.loginEsuat}>Login esuat</Typography> }
				<Box className={classes.passBox}>
				<Typography className={classes.parolaLabel}>Parolă</Typography>
				<OutlinedInput
				classes={{notchedOutline: classes.notchOut}}
					className={classes.password}
					error={error}
					value={password}
					onChange={onChange}
					label="Parolă"
					variant="outlined"
					type="password"
				/>
				</Box>
				<Button
				classes={{containedPrimary: classes.stilButonLogin}}
					variant="contained"
					onClick={doLogin}
					color="primary"
					disabled={isLoading}
					>
					Login
				</Button>
			</Box>
		</Box>
	)

}

export default Login
