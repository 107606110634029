import { all } from 'redux-saga/effects'
import loggerSaga from './debugger' // debug purpouse only
import loginSaga from './loginSaga'
import upsertSaga, { uploadMediaSaga } from './upsertSaga'
import eventsSaga from './eventsSaga'
import deleteSaga from './deleteSaga'

export default function* sagas() {
    yield all([
		// loggerSaga(), // debug purpouse only
		loginSaga(),
		upsertSaga(),
		uploadMediaSaga(),
		eventsSaga(),
		deleteSaga(),
	])
}
