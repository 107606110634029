import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Header from '../Components/Header/Header'
import Box from '@material-ui/core/Box'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Contact, Events, Admin } from '../Components'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { eventsActions } from '../Actions'
import SubHeader from '../Elements/SubHeader'
import './reset.css'
import { temporaryActions } from '../Actions' 
import useWindowSize from '../Hooks/useWindowSize'
import cn from 'clsx'
const background = require('../Images/background.png')

const useClasses = makeStyles(theme => ({
	rootContainer: {
		width: '100%',
		height: 'calc(100% - 156px)',
		maxWidth: '1200px',
		minWidth: '280px',
		borderRadius: '0px',
		marginLeft: 'auto',
		marginRight: 'auto',
		background: 'transparent',
		position: 'relative',
		padding: '10px',
	},
	rootContainerMobile: {
		width: '100%',
		height: 'auto',
		padding: '0px',
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'auto',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		// color: '#fff',
	},
	backgroundImg:{
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		objectFit: 'cover',
		zIndex: '-1',
		height: '100%',
		width: '100%',
	}
}))

const Navigation = () => {

	const classes = useClasses()
	const appLoaded = useSelector(state => state.temporary.appLoaded)
	const dispatch = useDispatch()
	const { width } = useWindowSize()
	const isMobile = useSelector(state => state.temporary.isMobile)

	useEffect(() => {
		dispatch(temporaryActions.setMobile(width < 840))
	},[width, dispatch])

	useEffect(() => {
		dispatch(eventsActions.getEvents())
		return () => null
	},[dispatch])

	if(!appLoaded) {
		return (
			<BrowserRouter>
				<Header />
				{!isMobile && <SubHeader/>}
				{
					!isMobile && <img src={background} alt="imagine fundal" className={classes.backgroundImg}/>
				}
				<Box className={cn(classes.rootContainer,{
            [classes.rootContainerMobile]: isMobile
        })}>
				{isMobile && <SubHeader/>}
					<Switch>
						<Route path="/proteste/:protestId" component={Events} />
					</Switch>
				</Box>
				<Backdrop className={classes.backdrop} open={!appLoaded}>
					<CircularProgress color="primary" />
				</Backdrop>
			</BrowserRouter>
		)
	}

	return (
		<BrowserRouter>
			<Header />
			{!isMobile && <SubHeader/>}
			{
					!isMobile && <img src={background} alt="imagine fundal" className={classes.backgroundImg}/>
				}
			<Box className={cn(classes.rootContainer,{
            [classes.rootContainerMobile]: isMobile
        })}>
			{isMobile && <SubHeader/>}
				<Switch>
					<Route path="/proteste/:protestId" component={Events} />
					<Route path="/proteste" component={Events} />
					<Route path="/contact" component={Contact} />
					<Route path="/admin/:protestId" component={Admin} />
					<Route path="/admin" component={Admin} />
					<Redirect to="/proteste" />
				</Switch>
			</Box>
			<Backdrop className={classes.backdrop} open={!appLoaded}>
				<CircularProgress color="primary" />
			</Backdrop>
		</BrowserRouter>
	)
}

export default Navigation
