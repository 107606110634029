// login reducer

export const SET_TOKEN = 'SET_TOKEN'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const DO_LOGOUT = 'DO_LOGOUT'
export const DO_LOGIN_SAGA = 'DO_LOGIN_SAGA'

// events

export const GET_EVENTS_SAGA = 'GET_EVENTS_SAGA'
export const ADD_EVENT_SAGA = 'ADD_EVENT_SAGA'
export const DELETE_EVENT_SAGA = 'DELETE_EVENT_SAGA'
export const SET_EVENTS = 'SET_EVENTS'
export const UPLOAD_MEDIA_EVENT_SAGA = 'UPLOAD_MEDIA_EVENT_SAGA'
export const RETRY_UPLOAD_MEDIA_SAGA = 'RETRY_UPLOAD_MEDIA_SAGA'
// temporary

export const SET_LOADING = 'SET_LOADING'
export const SET_GENERIC_ERROR = 'SET_GENERIC_ERROR'
export const SET_GENERIC_ERRORS = 'SET_GENERIC_ERRORS'
export const SET_TEMPORARY_VALUE = 'SET_TEMPORARY_VALUE'
export const SET_LOADING_STATUS_UPLOAD = 'SET_LOADING_STATUS_UPLOAD'
export const SET_MOBILE = 'SET_MOBILE'
