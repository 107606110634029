import React, { Fragment, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'clsx'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'
import CustomScroll from 'react-custom-scroll'
import Clipboard from './Clipboard'
import ScrollContainer from 'react-indiana-drag-scroll'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from '@material-ui/core/Modal'
import { useHistory } from 'react-router-dom'

const musicThumb = require('../Images/thumbmusic.png')
const videoThumb = require('../Images/thumbvideo.png')
const chainImg = require('../Images/chain.svg')
const closeImg = require('../Images/close.svg')

const useClasses = makeStyles(theme => ({
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginBottom: 10,
	},
	delete: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.primary.contrastText,
		marginRight: 10,
		"&:hover": {
			backgroundColor: theme.palette.error.dark,
		}
	},
	protestMainBox:{
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	protestTitle:{
		fontSize: '11.2pt',
		marginBottom: '2px',
		fontWeight: '800',
		textTransform: 'uppercase',
		color: 'white',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '6px',
		padding: '15px',
	},
	protestDescription:{
		fontSize: '9pt',
		fontWeight: '600',
		fontStyle: 'italic',
		marginBottom: '15px',
	},
	protestMainPic:{
		width: '100%',
		marginBottom: '20px',
		borderRadius: '14px',
	},
	featured:{
		height: '300px',
		width: '100%',
		margin: '0 auto',
		objectFit: 'cover',
		borderRadius: '14px',
		cursor: 'pointer',
	},
	protestContent:{
		width: '100%',
		marginBottom: '0px',
		fontSize: '9pt',
		fontWeight: '400',
		lineHeight: '1.46429em !important',
		letterSpacing: '0.00938em !important',
		'& p':{
			'& strong':{
				// fontSize: '14pt',
				fontWeight: '700 !important',
				// lineHeight: '70px',
			},
			lineHeight: '1.46429em !important',
		letterSpacing: '0.00938em !important',
		}
	},
	thumb: {
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		marginRight: 3,
		cursor: 'pointer',
		cursor: 'grab !important'
	},
	thumbActive: {
		boxShadow: theme.shadows[8],
	},
	noEventsBox: {
		textAlign: 'center',
		width: '100%',
	},
	articolBody:{
		backgroundColor: "white",
		borderRadius: '6px',
		padding: '15px',
		overflowX: 'auto',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 'calc(100% - 51px)'
	},
	thumbBox:{
		width: '100%',
		height: '100px',
		minHeight: '100px',
		display: 'flex',
		flexDirection: 'row',
		margin: '10px 0px',

	},
	// DE AICI IN JOS
	articleContainer: {
        display: 'flex',
		position: 'relative',
        flexDirection: 'row',
        maxWidth: '1024px',
		minWidth: '600px',
		height: '100%',
		minHeight: '320px',
        marginLeft: 'auto',
        marginRight: 'auto',
		wordBreak: 'break-word',
    },
	MainDesktopModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '70px',
    },
	articleContainerMobile: {
		display: 'flex',
		position: 'relative',
        flexDirection: 'row',
		minWidth: '280px',
		// minWidth: 'calc(100% - 40px)',
		height: '100%',
        maxWidth: '560px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
    articleMedia: {
        // height: '70px',
        width: '100%',
        // borderRadius: '6px',
        objectFit: 'cover',
        marginLeft:'auto',
        marginRight:'auto',
		borderRadius: '6px'
    },
	articleLeftImage: {
		width: 'calc(100% - 7px)',
		objectFit: 'cover',
	},
    articleLeftSubImageText: {
        position: 'absolute',
        left: '0px',
        top: '50px',
        width: 'auto',
        textAlign: 'center',
        wordSpacing: '11vw',
        fontSize: '5.2pt',
        maxWidth: '48px'
    },
    articleUnderImageItalicText:{
        fontFamily: "'Montserrat', sans-serif",
        fontSize:'8.5pt',
        fontWeight:400,
        alignSelf:'flex-end',
    },
    articleLeftContentContainer: {
        display: 'flex',
        background: theme.palette.primary.main,
        width: '48px',
        height: '48px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
		top: '-27px',
		left: '160px',
		overflow: 'hidden',
    },
	articleLeftContentContainerMobile: {
        display: 'flex',
        background: theme.palette.primary.main,
        width: '34px',
        height: '34px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
		top: '-27px',
		left: '160px',
		overflow: 'hidden',
    },
	articleBox: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row',
		top: '-27px',
		left: '23px'
	},
	articleBoxMobile: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row',
		top: '-17px',
		left: '10px'
	},
	articleLeftDayMobile: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight:900,
		backgroundColor: 'black',
		color: 'white',
		fontSize: '7pt',
		borderRadius: '6px',
		height: '34px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '5px',
		paddingLeft: '13px',
        paddingRight: '13px',
        letterSpacing:'1.2px',
    },
    articleLeftDayDesktop: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight:900,
		backgroundColor: '#404040',
		color: 'white',
		fontSize: '12.5pt',
		borderRadius: '6px',
		height: '48px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: '5px',
		paddingLeft: '17px',
        paddingRight: '17px',

	},
    articleContentTitle: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12.5pt',
        color: 'black',
        marginTop: '5px',
        width: '100%',
        fontWeight: 900,
        textTransform:'uppercase',
        letterSpacing:'0px',
        marginBottom:'0px',
        lineHeight:1.1,
    },
    articleContentTitleDesktop: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20.5pt',
        color: 'black',
        marginTop: '5px',
        width: 'auto',
        marginRight:'auto',
        fontWeight: 900,
        textTransform:'uppercase',
        letterSpacing:'0px',
        marginBottom:'0px',
        lineHeight:1.1,
        paddingTop:'20px',
		marginLeft: '-3px',
    },
    articleContentText: {
        // paddingLeft:'14px',
        // paddingRight:'19px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight:600,
        fontSize: '8pt',
        color: '#0a0a0a',
        marginTop: '10px',
		marginBottom: '10px',
		cursor: 'default',
		width: '100%',
		whiteSpace: 'pre-wrap', // keep spaces from text
        letterSpacing:'0',
    },
    articleContentTextDesktop: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight:400,
        fontSize: '9pt',
        color: 'black',
        marginTop: '10px',
        // marginLeft: '5px',
		marginBottom: '10px',
		cursor: 'default',
        width: 'auto',
        marginRight:'auto',
		whiteSpace: 'pre-wrap', // keep spaces from text

    },
    articleContentContainer: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        flexGrow: 1,
        flexDirection: 'column',
        background: 'white', // sau #fafafa
        borderRadius: '6px',
        // paddingTop:'40px',
        overflow:'auto',
        borderTop:'24px solid white',
        borderBottom:'7px solid white',
        paddingLeft:'20px',
        paddingRight:'20px'
    },
	articleBoxRightButtons: {
		display: 'flex',
		position: 'absolute',
		flexDirection: 'column',
		top: '-27px',
		right: '-20px',
	},
	articleBoxRightClose: {
		color: 'black',
		backgroundColor: theme.palette.primary.main,
		marginBottom: '5px',
		height: '48px',
		width: '48px',
		borderRadius: '6px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	articleBoxRightButtonsMobile: {
		display: 'flex',
		position: 'absolute',
		flexDirection: 'column',
		top: '-17px',
		right: '-20px',
	},
	articleBoxRightCloseMobile: {
		color: 'black',
		backgroundColor: theme.palette.primary.main,
		marginBottom: '5px',
		height: '34px',
		width: '34px',
		borderRadius: '6px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	articleLinkCopyed: {
		fontSize: '10pt',
		color: 'white',
		background: theme.palette.primary.main,
	},
	mainModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
		zIndex: '1100!important',
    }
}))

const music = ["mp3","wav","aif","mid","midi","mpa","ogg","wma"]
const video = ["3gp","avi","flv","h264","m4v","mp4","mkv","mov","mpg","mpeg","rm","swf","wmv","webm"]

const ViewEvent = ({ event, editable, onEdit }) => {

 	const classes = useClasses()
	const isMobile = useSelector(state => state.temporary.isMobile)
	const history = useHistory()
	 const [state, setState] = useState({
		photoIndex: 0,
      	isOpen: false,
	 })
	 const onCloseRequest = () => {
		 setState({ ...state, isOpen: false })
	 }

	 const onMovePrevRequest = () => {
		 setState(state => ({
			 ...state,
			 photoIndex: (state.photoIndex + event.media.length - 1) % event.media.length,
		 }))
	 }

	 const onMoveNextRequest = () => {
		 setState(state => ({
			 ...state,
			 photoIndex: (state.photoIndex + 1) % event.media.length,
		 }))
	 }

	 const onFeaturedClick = () => {
		 setState({
			 isOpen: true,
			 photoIndex: event.featured,
		 })
	 }

	 const imagesThumbs = useMemo(() => event?.media.map((image, index) => {
		 const onClick = () => {
			 setState({
				 isOpen: true,
				 photoIndex: index,
			 })
		 }

		 return (
			 <Thumbnail
				 key={image.toString()}
				 source={'/media/' + image}
				 index={index}
				 onClick={onClick}
				 className={classes.thumb}
		 	/>
	 	)
	}),[event])

	const [tooltip, openTooltip] = useState(false)

	if(!event) {
		return (
			<Box  className={classes.noEventsBox}>
				<Typography variant="h6" color="primary">Nu există proteste</Typography>
			</Box>
		)
	}



	const closeModal = () => {
		history.replace('/proteste')
	}

	const copyLink = e => {
		const link = window.location.href
		Clipboard(link)
		openTooltip(true)
		setTimeout(() => openTooltip(false),2000)
	}

	const { linie1_data_meniu, linie2_data_meniu, titlu_articol, abstract_articol, continut_articol } = event
	const { isOpen, photoIndex } = state
	const featured = event.media.find((_, i) => i === event.featured)

	return !isMobile ? (
		<Box className={classes.protestMainBox}>
			<Typography className={classes.protestTitle}>
				{linie1_data_meniu} {linie2_data_meniu} / {titlu_articol}
			</Typography>
			<Box className={classes.articolBody}>
				<Typography className={classes.protestDescription}>
					{abstract_articol}
				</Typography>
				{ featured && (
					<img
						src={'/media/' + featured}
						className={classes.featured}
						alt="featured"
						onClick={onFeaturedClick}
					/>
				)}
				<ScrollContainer className={classes.thumbBox}>
					{imagesThumbs}
				</ScrollContainer>
				{isOpen && (
					<Lightbox
						mainSrc={'/media/' + event.media[photoIndex]}
						nextSrc={'/media/' + event.media[(photoIndex + 1) % event.media.length]}
						prevSrc={'/media/' + event.media[(photoIndex + event.media.length - 1) % event.media.length]}
						onCloseRequest={onCloseRequest}
						onMovePrevRequest={onMovePrevRequest}
						onMoveNextRequest={onMoveNextRequest}
						enableZoom={false}
						imageLoadErrorMessage={<AudioVideoError source={'/media/' + event.media[photoIndex]} />}
					/>
				)}
				<Box className={classes.protestContent}
					dangerouslySetInnerHTML={{
						__html: continut_articol
					}}>
				</Box>
		</Box>
		</Box>
	) : (
		<Modal
		className={classes.mainModal}
		open={Boolean(event)}
		onClose={closeModal}
	  >
		<Box className={classes.articleContainerMobile}>
			<Box className={classes.articleBoxMobile}>
				<Box className={classes.articleLeftDayMobile}>
					{linie1_data_meniu} {linie2_data_meniu}
				</Box>
			</Box>


			<Box className={[classes.articleBoxRightButtonsMobile,"butoaneArticolComplet"].join(' ')}>
				<Box className={classes.articleBoxRightCloseMobile} onClick={closeModal}>
					<img src={closeImg} className={classes.articleLeftImage} alt="imagine anunt"/>
				</Box>

				<Box className={classes.articleLeftContentContainerMobile}>

					<Tooltip
                open={tooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
				placement="left"
                title="Link copiat ✓"
				classes={{
					tooltip: classes.articleLinkCopyed
				}}
              >
                <img src={chainImg} className={classes.articleLeftImage} alt="imagine anunt" onClick={copyLink}/>
              </Tooltip>
			  {isOpen && (
				  <Lightbox
					  mainSrc={'/media/' + event.media[photoIndex]}
					  nextSrc={'/media/' + event.media[(photoIndex + 1) % event.media.length]}
					  prevSrc={'/media/' + event.media[(photoIndex + event.media.length - 1) % event.media.length]}
					  onCloseRequest={onCloseRequest}
					  onMovePrevRequest={onMovePrevRequest}
					  onMoveNextRequest={onMoveNextRequest}
					  enableZoom={false}
					  imageLoadErrorMessage={<AudioVideoError source={'/media/' + event.media[photoIndex]} />}
				  />
			  )}
				</Box>
			</Box>

			<Box className={classes.articleContentContainer}>
				<h2 className={classes.articleContentTitle}>{titlu_articol}</h2>
				<p className={classes.articleContentText}>
					{abstract_articol}
				</p>


				{ featured && (
					<img
						src={'/media/' + featured}
						className={classes.featured}
						alt="featured"
						onClick={onFeaturedClick}
					/>
				)}
				<ScrollContainer className={classes.thumbBox}>
					{imagesThumbs}
				</ScrollContainer>

				<Box className={classes.protestContent}
					dangerouslySetInnerHTML={{
						__html: continut_articol
					}}>
					</Box>
			</Box>
		</Box>
		</Modal>
	)
}

const AudioVideoError = ({ source }) => {
	const extension = source.split('.').pop()

	if(music.includes(extension)) {
		return (
			<audio controls>
				<source src={source} type={"audio/" + extension}/>
				Browser-ul tău nu suportă redarea de fișiere audio
			</audio>
		)
	}

	if(video.includes(extension)) {
		return (
			<video controls height="80%" width="90%" style={{zIndex: 1300}}>
				<source src={source} type={"video/" + extension} />
				Browser-ul tău nu suportă redarea de fișiere video
			</video>
		)
	}

	return <Typography>Eroare la încarcare media</Typography>
}

const Thumbnail = React.memo(({ source, index, onClick, className }) => {
	const extension = source.split('.').pop()

	const renderImage = () => {
		if(music.includes(extension)) return musicThumb
		if(video.includes(extension)) return videoThumb
		return source
	}

	return (
		<img
			className={className}
			src={renderImage()}
			key={index}
			onClick={onClick}
		/>
	)
})

export default ViewEvent
